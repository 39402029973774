@import '../../../or_base/scss/theme-bootstrap';

/* Maskimizer CSS */

.maskimizer-trade {
  font-size: 18px;
  vertical-align: top;
  .collapsible__title & {
    font-size: 12px;
  }
  .sub-title p & {
    vertical-align: middle;
  }
}

.maskimizer-enter-to-win-wrapper .flex-tout__inner .flex-tout {
  &__bg-img {
    background-size: 100% 100%;
  }
  &--img__upper-image {
    width: 85%;
  }
}

.enter-to-win-email-container {
  margin: 20px 0;
  &.enter-to-win__mobile {
    @include breakpoint($landscape-up) {
      display: none !important;
    }
  }
  &.basic-formatter__item {
    @include breakpoint($medium-down) {
      display: none !important;
    }
    &.iPad_landscape {
      @include breakpoint($landscape-up) {
        display: block !important;
      }
      .enter-to-win-v1 .enter-to-win__submit-wrapper {
        margin: 30px 10px 10px;
      }
    }
  }
  .enter-to-win-v1 {
    margin: 0 auto;
    padding: 45px 0 35px;
    max-width: 910px;
    background-color: #f3faf4;
    border: 1px solid $color-black;
    position: relative;
    .enter-to-win__close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .enter-to-win__email-wrapper {
      float: left;
      margin: 0 10px 10px;
      @include breakpoint($landscape-up) {
        margin-left: 30px;
      }
      .form-text {
        line-height: 30px;
        width: 270px;
        height: 30px;
      }
    }
    .enter-to-win__tc-wrapper {
      font-size: 12px;
    }
    .enter-to-win__checkbox-wrapper {
      float: left;
      margin: 0 10px 10px;
      @include breakpoint($landscape-up) {
        margin: 0 10px;
      }
    }
    .enter-to-win__submit-wrapper {
      float: left;
      margin: 0 10px 10px;
      @include breakpoint($landscape-up) {
        margin: 0 0 10px;
      }
      .form-submit {
        width: 180px;
        height: 30px;
        line-height: 30px;
        font-size: 18px;
        padding: 0;
      }
    }
    .enter-to-win__arrow {
      top: -18px;
      left: 20%;
      min-height: 18px;
      min-width: 18px;
      position: absolute;
      z-index: 2;
      &::before,
      &::after {
        content: '';
        left: 0;
        position: absolute;
        top: 0;
      }
      &::after {
        border-top-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
        border-bottom-color: #f3faf4;
        border-style: solid;
        border-width: 9px;
        height: 0;
        width: 0;
      }
      &::before {
        border-top-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
        border-bottom-color: $color-darker-gray;
        border-style: solid;
        border-width: 10px;
        display: block;
        height: 0;
        margin-left: -1px;
        margin-top: -2px;
        width: 0;
      }
    }
    .enter-to-win__origin-email-wrapper > label,
    .enter-to-win__tc-wrapper > label {
      width: 100%;
      font-size: 12px;
      margin: 0;
      font-size: 12px;
      font-family: 'courier new';
    }
    input[type='checkbox'] ~ .label,
    input[type='checkbox'] ~ label {
      @include icon('radio');
    }
    input[type='checkbox']:checked ~ .label,
    input[type='checkbox']:checked ~ label {
      @include icon('radio--checked');
    }
  }
}

.enter_to_win_disable {
  display: none;
}

.meet-maskimizer-block .meet-maskimizer {
  .discover.discover--meet-maskimizer {
    padding-top: 81px;
    @include breakpoint($small-down) {
      padding-top: inherit;
    }
    .discover__top .discover__image-top img {
      left: 0;
    }
  }
  .discover__title {
    display: none;
  }
  .collapsible__title {
  }
  .basic-image {
    margin-bottom: 60px;
    @include breakpoint($medium-down) {
      padding: 0 5%;
    }
    img {
      margin: 0 auto;
      width: auto;
    }
  }
  .discover__text.discover__text--video {
    display: none;
  }
  .discover--meet-maskimizer .discover__top {
    margin-top: 45px;
    margin-bottom: 75px;
    @include breakpoint($small-down) {
      margin-bottom: 10px;
    }
  }
  .origins__milestone .origins__milestone-content {
    display: none;
  }
  .page-origins__milestone {
    .maskimizer__step1-container .origins__milestone-image {
      &:last-child img {
        margin-right: 11%;
        @include breakpoint($small-down) {
          margin: 0;
        }
        @include breakpoint($medium-down) {
          margin-right: 0;
          width: 100%;
        }
      }
      &:first-child img {
        margin-left: 23%;
        margin-top: 8%;
        @include breakpoint($small-down) {
          margin: 0;
        }
        @include breakpoint($medium-down) {
          margin-left: 0;
        }
      }
    }
    .maskimizer__step2-container .origins__milestone-image {
      &:first-child img {
        margin-left: 37%;
        margin-top: -2%;
        @include breakpoint($small-down) {
          margin: 0;
          width: 80%;
        }
        @include breakpoint($medium-down) {
          margin-left: 15%;
        }
      }
      &:last-child img {
        margin-right: 50%;
        margin-top: -11%;
        @include breakpoint($small-down) {
          margin: 0;
        }
        @include breakpoint($medium-down) {
          margin-right: 15%;
        }
      }
    }
    .maskimizer__email-form-container {
      @include breakpoint($medium-down) {
        display: block;
      }
      .origins__milestone-image {
        width: 50%;
        @include breakpoint($medium-down) {
          width: 100%;
          img {
            margin-left: 0;
          }
        }
      }
      .origins__milestone-content {
        display: block;
        width: auto;
      }
    }
  }
  .discover.discover--maskimizer-video-2 {
    margin-top: 20px;
    .discover__top {
      display: none;
    }
  }
  .discover__image-left {
    @include breakpoint($medium-down) {
      display: block;
    }
  }
}

.meet-maskimizer-block {
  .maskimizer-kit-right-wrapper {
    .title {
      font-family: $font--heading;
      font-size: 40px;
      margin-bottom: 35px;
      @include breakpoint($small-down) {
        font-size: 18px;
        margin-bottom: 17px;
      }
    }
    .sub-title {
      font-family: $font--text;
      font-size: 36px;
      font-style: normal;
      @include breakpoint($small-down) {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
    .sub-title-1 {
      margin-bottom: 40px;
      @include breakpoint($small-down) {
        margin-bottom: 20px;
      }
    }
    .sub-title-2 {
      margin-bottom: 20px;
    }
    p {
      margin: 0;
    }
  }
  .maskimizer-email-field-wrapper {
    .enter-to-win__email-wrapper {
      margin-bottom: 20px;
      .form-text {
        width: 80%;
        @include breakpoint($medium-down) {
          width: 100%;
        }
      }
    }
    .enter-to-win__submit-wrapper {
      margin: 20px 0;
      .form-submit {
        width: 50%;
        @include breakpoint($small-down) {
          width: 100%;
        }
      }
    }
    .enter-to-win__checkbox-wrapper {
      margin-left: 50px;
      @include breakpoint($medium-down) {
        margin-left: 0;
      }
      @include breakpoint($xxlarge-up) {
        margin-left: 70px;
      }
    }
    .enter-to-win__origin-email-wrapper > label,
    .enter-to-win__tc-wrapper > label {
      font-family: 'courier new';
      font-size: 12px;
      margin: 0;
      width: 100%;
      text-align: left;
      display: inline-block;
    }
    input[type='checkbox'] ~ .label,
    input[type='checkbox'] ~ label {
      @include icon('radio');
    }
    input[type='checkbox']:checked ~ .label,
    input[type='checkbox']:checked ~ label {
      @include icon('radio--checked');
    }
    .enter-to-win__close,
    .enter-to-win__arrow {
      display: none;
    }
  }
}

.enter-to-win-v1 {
  .enter-to-win__thankyou {
    text-align: center;
  }
  .enter-to-win__alert {
    margin: 0 10px 10px;
    @include breakpoint($landscape-up) {
      margin-left: 30px;
    }
    br {
      margin: 5px 0;
    }
  }
  .enter-to-win__greyout {
    opacity: 0.5;
  }
}
